import { Image, Text } from "@obvio/app";
import { RichText } from "@obvio/template";
import { Button, Stack } from "@obvio/ui";
import { easeInOut, motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import {
  Fixed,
  Main,
  Wrap,
  TopBlock,
  Description,
  ImageWrap,
  CtaWrap,
  ImageCtaWrap,
} from "./Blocks/components";

import type { ImageAsset } from "@obvio/app";
import type { MotionValue } from "framer-motion";
import type { ReactElement } from "react";

type BlockData =
  | {
      data: {
        text?: string;
        text2?: string;
        description?: string;
        image?: ImageAsset[];
      };
      title: string;
      template: "block";
    }
  | {
      data: {
        text?: string;
        text2?: string;
        description?: string;
        image?: ImageAsset[];
        ctaText?: string;
        url?: string;
      };
      title: string;
      template: "blockCta";
    };

type BlocksProps = {
  blocks: BlockData[];
};

export function Blocks({ blocks }: BlocksProps): ReactElement | null {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({ target: ref });

  if (blocks.length === 0) {
    return null;
  }

  return (
    <Main $elementsCount={blocks.length} ref={ref}>
      <Fixed>
        {blocks.map((block, idx) => (
          <Block
            // eslint-disable-next-line react/no-array-index-key -- Fine here
            key={String(idx)}
            scrollYProgress={scrollYProgress}
            count={blocks.length}
            block={block}
            idx={idx}
          />
        ))}
      </Fixed>
    </Main>
  );
}

type BlockProps = {
  count: number;
  block: BlockData;
  idx: number;
  scrollYProgress: MotionValue<number>;
};

function Block({
  count,
  block,
  idx,
  scrollYProgress,
}: BlockProps): ReactElement {
  const r = 0.9 / count;
  const translateY = useTransform(
    scrollYProgress,
    [0, r * idx, r * (idx + 1) + 0.1],
    [0, 0, -80],
    {
      ease: easeInOut,
    },
  );

  const scale = useTransform(translateY, [0, -75], [1.15, 1]);

  const scaleImg = useTransform(
    translateY,
    [0, -75],
    [1.25, 1],
  ) as unknown as number;

  const translateYVh = useTransform(translateY, [0, -75], ["0vh", "-75vh"]);

  return (
    <Wrap
      key={String(idx)}
      $idx={idx}
      // @ts-expect-error -- This actually works
      style={{ y: translateYVh, scale }}
      transition={{ duration: 0 }}
    >
      {block.template === "block" ? (
        <>
          <TopBlock $idx={idx} templateColumns="0.6fr 0.6fr 1fr">
            <Text>{block.data.text}</Text>
            <Text>{block.data.text2}</Text>
            <Description>
              <RichText value={block.data.description ?? ""} />
            </Description>
          </TopBlock>
          <ImageWrap>
            <motion.div
              style={{
                scale: scaleImg,
                height: "100%",
                transition: "all 300ms",
              }}
              transition={{ duration: 0 }}
            >
              <Image img={block.data.image?.[0]} sizes="800px" />
            </motion.div>
          </ImageWrap>
        </>
      ) : (
        <CtaWrap>
          <Stack kind="vertical">
            <Text>{block.data.text}</Text>
            <Text tag="h2">{block.data.text2}</Text>
            {block.data.url && block.data.ctaText ? (
              <div style={{ marginTop: "2.5rem" }}>
                <Button kind="secondary" href={block.data.url}>
                  {block.data.ctaText}
                </Button>
              </div>
            ) : null}
          </Stack>
          <Stack kind="vertical" spacing="large">
            <div>
              <RichText value={block.data.description ?? ""} />
            </div>
            <ImageCtaWrap $ratio={block.data?.image?.[0]?.ratio ?? 1}>
              <Image img={block.data.image?.[0]} sizes="800px" />
            </ImageCtaWrap>
          </Stack>
        </CtaWrap>
      )}
    </Wrap>
  );
}
