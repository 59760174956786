import { notDesktop, styled } from "@obvio/app";
import { Grid } from "@obvio/ui";
import { motion } from "framer-motion";

export const colors = ["#C8CD0B", "#07342F", "#B7D0DC", "#F7F3EA", "#1C4F4A"];

export const TopBlock = styled(Grid) <{ $idx: number }>`
  border-radius: ${(theme) => theme.radius.small};
  background: ${(_, { $idx }) => colors[$idx % colors.length]};
  * {
    color: ${(theme, { $idx }) =>
    $idx === 1 || $idx === 4 ? "white" : theme.colors.primary.dark};
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 2.5rem 5rem;
  @media ${notDesktop} {
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    gap: 2rem;
  }
`;

export const Wrap = motion(styled.div<{ $idx: number }>`
  position: absolute;
  min-height: 12.5rem;
  width: 90%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: ${(_, { $idx }) => $idx};
  top: ${(_, { $idx }) => `calc(100% + ${$idx + 10}vh)`};

  @media ${notDesktop} {
    width: 100%;
  }
`);

export const ImageWrap = styled.div`
  max-height: 40rem;
  height: 60vh;
  width: 100%;
  top: 7.5rem;
  position: absolute;
  border-radius: ${(theme) => theme.radius.small};
  overflow: hidden;
`;

export const ImageCtaWrap = styled.div<{ $ratio: number }>`
  width: 80%;
  aspect-ratio: ${(_, { $ratio }) => $ratio};
  margin-left: auto !important;
  margin-right: auto !important;
`;

export const Main = styled.div<{ $elementsCount: number }>`
  top: -65vh;
  height: ${(_, { $elementsCount }) => `${$elementsCount * 150 - 50}vh`};
  position: relative;
`;

export const Fixed = styled.div`
  position: sticky;
  height: 50vh;
  top: 33vh;
`;

export const Description = styled.div`
  @media ${notDesktop} {
    grid-column: span 2;
  }
`;

export const CtaWrap = styled.div`
  border-radius: ${(theme) => theme.radius.small};
  background: ${(theme) => theme.colors.primary.dark};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 7.5rem 5rem;
  * {
    color: white;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(theme) => theme.spacing.extraLarge};
		@media ${notDesktop} {
		grid-template-columns: 1fr;
		padding: 1rem;
	}
	}
`;
